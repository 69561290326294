import React, { useEffect, useState } from "react";
import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import clsx from "clsx";
import {
	Box,
	Button,
	Container,
	Typography,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	withStyles
} from "@material-ui/core";
import { Hero } from "@hyperobjekt/material-ui-website";
import Block from "gatsby-theme-hypersite/block";
import Header from "gatsby-theme-hypersite/header/header";
import { default as Layout } from "gatsby-theme-hypersite/layout";
import { Map, Table } from "../components";
import { JurisdictionSelector } from "../components/common";
import { getUrl, formatDate, slugify } from "../utils";
import { COLORS } from "../theme";

const TABLE_SELECT_LABEL = "Limit county results to";
const TABLE_SELECT_PLACEHOLDER = "state or territory";

const MAP_SELECT_LABEL = "Or select here: ";
const MAP_SELECT_PLACEHOLDER = "Choose a state or territory";

const TrackerPage = ({ classes, data, ...props }) => {
	const { root, container, content, imageWrapper, image } = classes;
	const tracker = data?.tracker;
	const national = data?.national;
	const counties = data?.allCounties.nodes;
	const states = data?.allStates.nodes;

	const [tableData, setTableData] = useState(counties);
	const [tableState, setTableState] = useState("");

	const sortedReleases = tracker?.releases.sort(
		(a, b) => new Date(b.date) - new Date(a.date)
	);

	const handleJurisdictionChange = val => {
		const url = getUrl("data", "eviction", slugify(val));
		navigate(url);
	};

	useEffect(() => {
		const newCounties = counties.filter(c =>
			tableState ? tableState === c.state_name : true
		);
		setTableData(newCounties);
	}, [tableState]);

	return (
		<Layout
			{...props}
			gradientVariant={true}
			image={tracker.hero}
			lede={tracker.description}
		>
			<div className={clsx(classes.root)}>
				<Block
					ContainerProps={{
						maxWidth: "lg"
					}}
				>
					<Container maxWidth="lg">
						<Typography component="h2" variant="h3" align="center">
							Overview of State and Territory Data
						</Typography>
						<Typography align="center">
							Click on a state or territory in the map below to visit the
							jurisdiction's profile page.
						</Typography>
						<JurisdictionSelector
							value={""}
							options={states.map(s => s.name)}
							label={MAP_SELECT_LABEL}
							placeholder={MAP_SELECT_PLACEHOLDER}
							handleChange={handleJurisdictionChange}
						/>
						{sortedReleases.length ? (
							<Typography variant="caption" className={classes.lastUpdated}>
								Data last updated {formatDate(national.data_last_updated)}
							</Typography>
						) : (
							""
						)}
					</Container>

					<Map
						className={classes.map}
						level="nation"
						interactive="states"
						pageName=""
						pageFips=""
						bbox={national.bbox}
						albers={true}
						showLegend={true}
						maxVal={Number(national.filings_rate_threshold)}
						data={{
							states,
							counties
						}}
					/>
				</Block>

				<Block>
					<Typography component="h2" variant="h3" align="center">
						Overview of Jurisdiction Data
					</Typography>

					<Table
						className={classes.table}
						data={tableData}
						defaultOrderBy="filings_rate"
						defaultDirection="desc"
						defaultRowsPerPage={10}
						selectBy="state_name"
						schema={[
							{
								key: "state_name",
								label: "State / Territory",
								link: true
							},
							{
								key: "name",
								label: "County",
								link: true
							},
							{
								key: "filings_count",
								label: "Filings",
								tooltip: "Since March 2020"
							},
							{
								key: "filings_rate",
								label: "Filings rate",
								percent: true,
								tooltip: "Per 100 renter households from {{dates}}"
							},
							{
								key: "filings_latest",
								label: "Latest month's filings",
								tooltip: "Filings in {{date}}"
							},
							{
								key: "poverty_rate",
								label: "Poverty rate",
								percent: true
							}
						]}
						palette={{
							background: COLORS.dark,
							text: COLORS.light
						}}
						highlight={{
							title: "April 2022",
							start: 2,
							end: 4
						}}
					/>
				</Block>
			</div>
		</Layout>
	);
};

TrackerPage.defaultProps = {
	// hero: {
	// 	title: "Eviction Tracker",
	// 	lede: "The Legal Services Corporation (LSC) Eviction Tracker provides access to multi-year trend data on eviction filings for 1,250 counties and municipalities in 30 states and territories across the United States.",
	// image: "/images/eviction-hero.png",
	// }
};

TrackerPage.propTypes = {};

export default withStyles(theme => ({
	root: {
		"& .MuiTypography-h2": {
			paddingTop: theme.spacing(5),
			paddingBottom: theme.spacing(1)
		},
		"& .MuiTypography-h3": {
			marginBottom: theme.spacing(4)
		}
	},
	container: {
		width: "unset",
		margin: theme.spacing("auto", 0, 0, 0)
	},
	introBlock: {
		padding: theme.spacing(15, 0),
		marginTop: 0,
		marginBottom: theme.spacing(10),
		backgroundColor: theme.palette.blue.dark,
		color: theme.palette.text.light,
		"& *": {
			color: theme.palette.text.light
		}
	},
	map: {
		minHeight: "800px !important",
		"& .mapboxgl-map": {
			minHeight: "800px !important"
		}
	},
	lastUpdated: {
		fontStyle: "italic",
		paddingTop: theme.spacing(2),
		textAlign: "center",
		display: "block"
	}
}))(TrackerPage);

export const pageQuery = graphql`
	query ($id: String) {
		mdx(id: { eq: $id }) {
			id
			body
			slug
			frontmatter {
				hero
				thumb
				name
				meta {
					title
					description
					keywords
				}
				store {
					key
					value
				}
			}
		}
		tracker: trackers(slug: { eq: "eviction" }) {
			name
			description
			hero
			releases {
				date
			}
		}
		national {
			filings_rate_threshold
			bbox
			data_last_updated
		}
		allStates(sort: { fields: name }) {
			nodes {
				id
				fips
				name
				short
				filings_count
				filings_latest
				filings_latest_date
				counties_total
				counties_tracked
				bbox
			}
		}
		allCounties(sort: { fields: name }) {
			nodes {
				id
				fips
				name
				state_fips
				state_name
				state_short
				filings_rate
				filings_rate_dates
				filings_count
				filings_latest
				filings_latest_date
				poverty_rate
				fips_if_multi_county
				bbox
			}
		}
	}
`;
